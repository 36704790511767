<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/brand/brand' }">品牌列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" ref="form" :model="form" size="medium"
                             :label-width="this.env.label_width">
                        <el-form-item label="品牌负责人姓名">
                            <el-input v-model="form.actual_name" :maxlength="10"></el-input>
                        </el-form-item>
                        <el-form-item label="负责人手机号">
                            <el-input v-model="form.phone" :maxlength="11"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证正反面">
                            <div style="display: flex; ">
                                <Qnupload v-model="form.card_front" :sum="1"/>
                                <Qnupload v-model="form.card_back" :sum="1"/>
                            </div>
                        </el-form-item>
                        <el-form-item label="品牌名称">
                            <el-input v-model="form.brand_home.brand_name" :maxlength="10"></el-input>
                        </el-form-item>
                        <el-form-item label="品牌标签">
                            <el-select
                                    v-model="form.brand_home.brand_tag"
                                    multiple
                                    filterable
                                    placeholder="请选择品牌标签（必选，最多3个）"
                                    :loading="false"
                                    :multiple-limit="3"
                                    style="width: 100%">
                                <el-option
                                        v-for="(item,key) in brand_tag"
                                        :key="key"
                                        :label="item.tag_name"
                                        :value="item.tag_uuid">
                                    <span>{{ item.tag_name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="品牌头像">
                                    <Qnupload v-model="form.brand_home.brand_avatar" :sum="1" :isclipper="true" :compress="false"
                                              :fixedNumber="[1,1]"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="品牌背景图">
                                    <Qnupload v-model="form.brand_home.brand_photo" :sum="1" :isclipper="true" :compress="false"
                                              :fixedNumber="[750,400]"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="营业执照">
                                    <Qnupload v-model="form.business_photo" :sum="1"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="品牌LOGO">
                                    <Qnupload v-model="form.brand_logo" :sum="1"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="商标证书">
                                    <Qnupload v-model="form.brand_photo" :sum="1"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="品牌故事">
                            <Wangeditor ref="wangeditor" v-model="form.brand_home.brand_text" idindex="1"
                                        seewWidth="50%"/>
                        </el-form-item>
                        <el-form-item label="关联门店">
                            <el-select
                                    v-model="form.brand_home.store_uuid_all"
                                    multiple
                                    filterable
                                    placeholder="请选择门店(非必选)"
                                    :loading="false"
                                    style="width: 100%">
                                <el-option
                                        v-for="(item,key) in store_optionals"
                                        :key="key"
                                        :label="item.store_name"
                                        :value="item.store_uuid">
                                     <span style="color: #8492a6; font-size: 13px">
                                            【{{ item.company_name }}】
                                     </span>
                                    <span>{{ item.store_name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="申请理由">
                            <el-input type="textarea" :rows="3" :maxlength="200" show-word-limit
                                      v-model="form.info_reason"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="is_auth('approve.brand.add')"
                                       size="medium" type="primary" @click="save">提交申请
                            </el-button>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Wangeditor,
        Qnupload,
    },
    data() {
        return {
            page_name: '品牌详情',
            loading: true,
            certified_uuid: '',
            form: {
                actual_name: '',
                phone: '',
                card_front: '',
                card_back: '',
                business_photo: '',
                brand_logo: '',
                brand_photo: '',
                info_reason: '',

                brand_home: {
                    brand_name: '',
                    brand_avatar: '',
                    brand_photo: '',
                    brand_tag: [],
                    store_uuid_all: [],
                    brand_text: '',
                },
            },
            store_optionals: [],            // 可选门店信息
            brand_tag: [],            // 可选品牌标签

        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {

    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getStore() // 获取可用门店
            this.get_brand_tag() // 获取可用品牌标签
            let certified_uuid = this.$route.query.certified_uuid
            if (certified_uuid !== undefined) {
                this.certified_uuid = certified_uuid
                this.getinfo();
            } else {
                this.loading = false
            }
        },
        // 查询用户信息
        getinfo() {
            let postdetails = {
                api_name: "approve.brand.getinfo",
                token: this.Tool.get_l_cache('token'),
                certified_uuid: this.certified_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdetails, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    this.$refs.wangeditor.append(json.data.brand_home.brand_text);
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 保存
        save() {
            let postdata = {
                api_name: "approve.brand.add",
                token: this.Tool.get_l_cache('token'),
            }

            Object.assign(postdata, this.form);
            // 判断是否有用户id，如果有，则是更新
            if (this.certified_uuid !== '') {
                postdata.certified_uuid = this.certified_uuid
                postdata.api_name = 'approve.brand.saves'
            }

            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.$router.push({path: '/brand/brand'})    // 返回列表
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })

        },
        // 返回
        isreturn() {
            this.$router.push({path: '/brand/brand'})
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "approve.brandhome.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取品牌标签
        get_brand_tag() {
            let postdata = {
                api_name: "approve.brandhome.gettag",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.brand_tag = json.data.list
                } else {
                    this.brand_tag = []
                    this.Tool.errormes(json)
                }

            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
